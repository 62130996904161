@import "../config/typography";
@import "../config/variables";

.keyboardContainer {
  display: flex;
  align-items: center;
  background-color: #eaecf0;
  border-radius: 12px;
  position: absolute;
  gap: 24px;
  padding: 12px;
  box-sizing: border-box;
  width: 1363px;
  
  &.number{
    width: auto;
  }

  z-index: 1;

  .hg-theme-default .hg-row:not(:last-child) {
    margin-bottom: 6px;
  }
  .hg-row {
    &:last-child() {
      margin: 0 !important;
    }
    .hg-button {
      width: 63px;
      height: 61px;
      margin-right: 6px !important;
      border-radius: 0;
      span {
        //styleName: tit/3-r;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: center;
      }
    }
  }

  .hg-button-bksp,
  .hg-button-enter,
  .hg-button-capslock,
  .hg-button-escape {
    background: #d5d8de;
    min-width: 124px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hg-button-bksp {
    font-size: 30px;
  }
  .hg-layout-shift {
    .hg-button-capslock {
      background-color: $principal;
      color: #fff;
    }
  }

  .simple-keyboard-main {
    padding: 0;
  }
  .numpad {
    padding: 0;
  }
  // .simple-keyboard-main {
  //   .hg-rows {
  //     display: grid;
  //     grid-template-columns: auto 168px;
  //     gap: 5px;

  //     .hg-row {
  //       grid-column: 1 / 3;
  //       margin: 0;
  //       &:nth-child(2),
  //       &:nth-child(3) {
  //         grid-column: 1 / 2;
  //       }
  //       &:last-child {
  //         grid-row: 2 / 4;
  //         grid-column: 2/ 3;
  //       }
  //     }
  //   }
  // }
}
