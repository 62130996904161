@import '../config/functions';

.bg-principal { background-color: $principal !important; }
.bg-secundario { background-color: $secundario !important; }
.bg-negro { background-color: $negro !important; }
.bg-g9 { background-color: $g9 !important; &:hover { background-color: $g9 !important; } }
.bg-g8 { background-color: $g8 !important; &:hover { background-color: $g8 !important; } }
.bg-g7 { background-color: $g7 !important; &:hover { background-color: $g7 !important; } }
.bg-g6 { background-color: $g6 !important; &:hover { background-color: $g6 !important; } }
.bg-g5 { background-color: $g5 !important; &:hover { background-color: $g5 !important; } }
.bg-g4 { background-color: $g4 !important; &:hover { background-color: $g4 !important; } }
.bg-g3 { background-color: $g3 !important; &:hover { background-color: $g3 !important; } }
.bg-g2 { background-color: $g2 !important; &:hover { background-color: $g2 !important; } }
.bg-g1 { background-color: $g1 !important; &:hover { background-color: $g1 !important; } }
.bg-blanco { background-color: $blanco !important; &:hover { background-color: $blanco !important; } }
.bg-info { background-color: $info !important; &:hover { background-color: $info !important; } }
.bg-alerta { background-color: $alerta !important; &:hover { background-color: $alerta !important; } }
.bg-green-2 { background-color: $green-2 !important; &:hover { background-color: $green-2 !important; } }

.text-principal { color: $principal !important; }
.text-secundario { color: $secundario !important; }
.text-negro { color: $negro !important; }
.text-g9 { color: $g9 !important; }
.text-g8 { color: $g8 !important; }
.text-g7 { color: $g7 !important; }
.text-g6 { color: $g6 !important; }
.text-g5 { color: $g5 !important; }
.text-g4 { color: $g4 !important; }
.text-g3 { color: $g3 !important; }
.text-g2 { color: $g2 !important; }
.text-g1 { color: $g1 !important; }
.text-blanco { color: $blanco !important; }
.text-info { color: $info !important; }
.text-alerta { color: $alerta !important; }
.text-green-2 { color: $green-2 !important; }

.border-principal { border-color: $principal !important; }
.border-secundario { border-color: $secundario !important; }
.border-negro { border-color: $negro !important; }
.border-g9 { border-color: $g9 !important; }
.border-g8 { border-color: $g8 !important; }
.border-g7 { border-color: $g7 !important; }
.border-g6 { border-color: $g6 !important; }
.border-g5 { border-color: $g5 !important; }
.border-g4 { border-color: $g4 !important; }
.border-g3 { border-color: $g3 !important; }
.border-g2 { border-color: $g2 !important; }
.border-g1 { border-color: $g1 !important; }
.border-blanco { border-color: $blanco !important; }
.border-info { border-color: $info !important; }
.border-alerta { border-color: $alerta !important; }
.border-green-2 { border-color: $green-2 !important; }

.sombra-active {
    background: #C4C4C4 !important;
    box-shadow: inset 0px 0px px-to-vw(12px) rgba(0, 0, 0, 0.2) !important;
}