@import './functions';

.font-roboto {
    font-family: 'Roboto', sans-serif !important;
}

.font-montserrat {
    font-family: 'Montserrat', sans-serif !important;
}

.tit {
    &-1-b {
        /* tit/1-b */
        @extend .font-montserrat;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: px-to-vw(48px) !important;
        line-height: px-to-vw(52px) !important;
        
        /* or 108% */
        letter-spacing: 0.015em !important;
        font-feature-settings: 'zero' on, 'liga' off !important;
    }
    &-2-b{
        //styleName: tit/2-b;
        @extend .font-montserrat;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;

    }
    &-2-r {
        /* tit/2-r */
        @extend .font-montserrat;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: px-to-vw(36px) !important;
        line-height: px-to-vw(42px) !important;
        font-feature-settings: 'zero' on !important;
    }
    &-3-r {
        /* tit/3-r */
        @extend .font-montserrat;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: px-to-vw(24px) !important;
        line-height: px-to-vw(29px) !important;
        font-feature-settings: 'zero' on !important;
    }
    &-3-b {
        /* tit/3-b */
        @extend .font-montserrat;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: px-to-vw(24px) !important;
        line-height: px-to-vw(29px) !important;
        font-feature-settings: 'zero' on !important;
    }
    &-5-r {
        /* tit/5-r */
        @extend .font-montserrat;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: px-to-vw(18px) !important;
        line-height: px-to-vw(22px) !important;
        font-feature-settings: 'zero' on !important;
    }
    &-5-b {
        /* tit/5-b */
        @extend .font-montserrat;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: px-to-vw(18px) !important;
        line-height: px-to-vw(22px) !important;
        font-feature-settings: 'zero' on !important;
    }
}

.txt {
    &-1-r {
        /* txt/1-r */
        @extend .font-roboto;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: px-to-vw(18px) !important;
        line-height: px-to-vw(23px) !important;
        font-feature-settings: 'ss01' on, 'case' on !important;
    }
    &-1-b {
        /* txt/1-b */
        @extend .font-roboto;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: px-to-vw(18px) !important;
        line-height: px-to-vw(23px) !important;
        font-feature-settings: 'ordn' on, 'liga' off !important;
    }

    &-2-r {
        /* txt/2-r */
        @extend .font-roboto;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: px-to-vw(15px) !important;
        line-height: px-to-vw(21px) !important;
        font-feature-settings: 'zero' on !important;
    }
    &-3 {
        /* txt/3 */
        @extend .font-roboto;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: px-to-vw(13px) !important;
        line-height: px-to-vw(14px) !important;
        font-feature-settings: 'ss01' on, 'case' on !important;
    }
}

.btn {
    &-1 {
        @extend .font-montserrat;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: px-to-vw(18px) !important;
        line-height: px-to-vw(29px) !important;
        font-feature-settings: 'zero' on !important;
    }
    &-2 {
        /* btn/2 */
        @extend .font-montserrat;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: px-to-vw(18px) !important;
        line-height: px-to-vw(18px) !important;
    }
    &-3 {
        /* btn/3 */
        @extend .font-roboto;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: px-to-vw(18px) !important;
        line-height: px-to-vw(22px) !important;
        text-transform: uppercase !important;
        font-feature-settings: 'ss01' on, 'case' on !important;
    }
    &-btn3 {
        /* btn/btn3 */
        @extend .font-montserrat;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: px-to-vw(14px) !important;
        line-height: px-to-vw(18px) !important;
        text-transform: uppercase !important;
    }
}

.precio {
    &-1 {
        /* $/1 */
        @extend .font-montserrat;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: px-to-vw(21px) !important;
        line-height: px-to-vw(18px) !important;
        font-feature-settings: 'zero' on !important;
    }
    &-antes {
        /* $/antes */
        @extend .font-montserrat;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: px-to-vw(18px) !important;
        line-height: px-to-vw(18px) !important;
        text-decoration-line: line-through !important;
        font-feature-settings: 'zero' on !important;
    }
    &-antes-2 {
        /* $/antes-2 */
        @extend .font-roboto;
        font-style: normal !important;
        font-weight: 300 !important;
        font-size: px-to-vw(14px) !important;
        line-height: px-to-vw(22px) !important;
        text-decoration-line: line-through !important;
        font-feature-settings: 'ss01' on, 'case' on !important;
    }
    &-precio-2 {
        @extend .font-roboto;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: px-to-vw(18px) !important;
        line-height: px-to-vw(24px) !important;
        font-feature-settings: 'zero' on !important;
    }
}

.tr {
    &-1 {
        @extend .font-montserrat;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: px-to-vw(15px) !important;
        line-height: px-to-vw(18px) !important;
    }
    &-b {
        @extend .font-montserrat;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: px-to-vw(15px) !important;
        line-height: px-to-vw(18px) !important;
    }
}

.h1 {
    &-r {
        @extend .font-montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: px-to-vw(48px);
        line-height: px-to-vw(48px);
    }
}

.h2 {
    &-r {
        @extend .font-montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: px-to-vw(36px);
        line-height: px-to-vw(36px);
    }
}

.h4 {
    &-b {
        @extend .font-montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: px-to-vw(21px);
        line-height: px-to-vw(24px);
    }
    &-r {
        @extend .font-montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: px-to-vw(21px);
        line-height: px-to-vw(24px);
    }
}

.t2 {
    &-r {
        @extend .font-roboto;
        font-style: normal;
        font-weight: 300;
        font-size: px-to-vw(14px);
        line-height: px-to-vw(18px);
    }
}

.t3 {
    @extend .font-roboto;
    font-style: normal;
    font-weight: 500;
    font-size: px-to-vw(13px);
    line-height: px-to-vw(14px);
}