// colors
$principal: #FF7D00;
$secundario: #7DC832;
$negro: #000000;
$g9: #282828;
$g8: #505050;
$g7: #6E6E6E;
$g6: #6E6E6E;
$g5: #AFAFAF;
$g4: #C8C8C8;
$g3: #E1E1E1;
$g2: #F0F0F0;
$g1: #F5F5F5;
$blanco: #FFFFFF;
$info: #5266CC;
$alerta: #E63939;
$green-2: #E63939;