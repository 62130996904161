@import "./bootstrap.css";

// config
@import "./config/variables";
@import "./config/typography";
@import "./config/functions";

// global
@import "./global/layout";
@import "./global/colors";

// components
@import "./components/button";
@import "./components/modal";
@import "./components/form";

//Modal
.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999;
}

.my-card {
  width: 620px;
  height: auto;
  padding: 36px;
  background: #ffffff;
  border: solid 3px #e53939;
  border-radius: 12px;
}

.modal-title-content {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 23px;
  color: #e53939;
  font-weight: 500;
}
.cart-modal-content {
  font-size: 16px !important;
}

.modal-title {
  font-weight: bold;
}
.validateDate {
  width: 100%;
  margin: 24px 0px;
  padding: 12px 36px;
  background: #ff7d00;
  border-radius: 6px;
  color: white;
  list-style: none;
  text-decoration: none;
  border: none;
  outline: none;
  font-family: Montserrat;
  font-size: 18px;
}
.selectPago {
  width: 100%;

  padding: 12px 12px;
  border-radius: 6px;
  color: #ff7d00;
  text-transform: uppercase;
  list-style: none;
  text-decoration: none;
  border: none;
  outline: none;
  background: #ffffff;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
}
.cart-modal-content {
  font-size: 18px;
  line-height: 30px;

  display: flex;
  align-items: center;
}
