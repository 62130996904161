@import '../../styles/config/variables';
@import '../../styles/config/typography';
@import '../../styles/global/colors';

.how-it-works {
    .card {
        background: #F5F5F5;
        border-radius: px-to-vw(24px);
        border: none;
        &-body {
            padding: px-to-vw(12px) px-to-vw(12px) px-to-vw(24px);
        }
        &-img {
            width: px-to-vw(180px);
            max-width: 100%;
            height: auto;
        }
    }
    .card-bottom {
        border-width: px-to-vw(3px) !important;
        .card-body {
            padding: px-to-vw(17px);
        }   
    }
    .badge {
        background-color: $secundario;
        width: px-to-vw(36px);
        height: px-to-vw(36px);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        @extend .tit-5-b;
        color: $blanco !important;
    }
    .btn-start {
        height: px-to-vw(53px);
        padding: px-to-vw(12px) px-to-vw(36px);
        background-color: $principal;
        border-radius: px-to-vw(6px);
        &:hover {
            background-color: $principal !important;
        }
        &-success {
            @extend .bg-secundario;
            border-color: $secundario !important;
        }
    }
    &-modal.modal-dialog {
        max-width: px-to-vw(936px);
    }
}