@import '../../styles/config/functions';

.header {
  width: 100%;
  height: px-to-vw(72px);
  border-style: solid;
  border-width: 1px;
  &-conteiner {
    padding-left: px-to-vw(48.5px);
    padding-right: px-to-vw(48.5px);
  }
  &-logo {
    width: px-to-vw(102px);
  }
}