@import "./typography";
@import "./variables";

@mixin input {
  box-sizing: border-box;
  padding: 12px;
  background-color: $g1;
  border: 1px solid $g3;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @extend .txt-1-r;

  &:hover {
    border: 1px solid $g5;
    color: $g6;
  }

  &:active {
    border: 1px solid $g1;
    color: $principal;
  }
  &:focus-within {
    background-color: white;
    color: #000;
    outline: none;
  }
}

@mixin button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: $principal;
  border-radius: 6px;
  padding: 15px 24px;
  color: $blanco;
  @extend .btn-1;

  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  }
  &:active {
    box-shadow: inset 0px 0px 12px rgba(0, 0, 0, 0.25);
  }
}
