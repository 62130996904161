@import "../config/typography";
@import "../config/variables";
@import "../config/mixins";

.sucursales {
  margin: 36px auto;
  h1 {
    @extend .tit-2-b;
    text-align: center;
  }
  h2 {
    @extend .h4-r;
    text-align: center;
    margin: 12px 0;
  }

  form {
    display: flex;
    width: px-to-vw(840px);
    position: relative;
    height: 42px;
    img {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translate(12px, -50%);
    }
    & > div{

      flex: 1;
      input {
        height: 42px;
        @include input;
        border-radius: 6px 0 0 6px;
        padding-left: 45px;
        width: 100%
      }
    }
    button {
      @include button;
      border-radius: 0 6px 6px 0;
      flex: 0 0 191px;
    }
    margin-bottom: 36px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 450px;
    width: px-to-vw(838px);
    overflow-y: auto;
    overflow-x: hidden;
    .sucursal {
      border-radius: 12px;
      border: 2px solid $g3;
      background: white;
      width: 100%;
      margin-bottom: 12px;
      display: grid;
      gap: 24px;
      grid-template-columns: 1fr 1fr 0.5fr;
      align-items: center;
      height: 120px;
      padding: 0 36px;
      .sucursal-nombre {
        color: $principal;
        @extend .tit-3-b;
        display: flex;
        align-items: center;
        .point {
          margin-right: 6px;
        }
      }
      .sucursal-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 12px;
        div {
          color: $g9;
          @extend .tit-5-b;

          span {
            @extend .tit-5-r;
            white-space: nowrap;
            &:nth-child(2){
              margin-left: 6px;
            }
          }
        }
      }
      .check {
        justify-self: end;
        .circle {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 2px solid $g3;
          background-color: white;
          display: flex !important;

        }
      }

      &.active {
        border: 2px solid $principal;
      }
    }
  }
  .confirmation{
    margin-top: 32px;
    @include button;
    height: 52px;
    width: 100%;
  }
}

iframe {
  display: none !important;
}
