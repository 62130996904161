.modal {
    &-dialog {
        max-width: px-to-vw(623px);
        border-radius: px-to-vw(12px);
    }
    &-body {
        padding: px-to-vw(36px);
    }
    &-content {
        border-radius: px-to-vw(12px);
    }
}