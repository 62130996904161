@import "../../styles/config/variables";
@import "../../styles/config/functions";

.cart {
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  &-spinner {
    -webkit-animation: rotating 1.5s linear infinite;
    -moz-animation: rotating 1.5s linear infinite;
    -ms-animation: rotating 1.5s linear infinite;
    -o-animation: rotating 1.5s linear infinite;
    animation: rotating 1.5s linear infinite;
  }
  &-input-search {
    width: px-to-vw(670px);
    .input-group-prepend .input-group-text {
      background: none;
      border-top-left-radius: px-to-vw(6px);
      border-bottom-left-radius: px-to-vw(6px);
      border: 1px solid #afafaf;
      border-right: none;
    }
    input {
      border: 1px solid #afafaf !important;
      border-left: none !important;
      border-radius: 0;
    }
    .btn {
      background: $principal !important;
      border: none !important;
      width: px-to-vw(191px);
      height: 3.51391vw;
      border-top-right-radius: px-to-vw(6px);
      border-bottom-right-radius: px-to-vw(6px);
    }
  }
}

.search {
  .slick {
    &-track {
      margin-left: 0 !important;
    }
    &-slide {
      padding: px-to-vw(12px);
    }
    &-next,
    &-prev {
      width: px-to-vw(36px);
      border: 1px solid $g3;
      height: calc(100% - 1.464vw);
      border-radius: 0 px-to-vw(6px) px-to-vw(6px) 0;
      background-color: $blanco !important;
      background-image: url("../../assets/arrow-right.svg") !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      z-index: 9;
      &:before {
        display: none !important;
      }
    }
    &-prev {
      top: px-to-vw(10px);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    &-disabled {
      display: none !important;
    }
  }
  .card {
    border: none !important;
    background: $blanco;
    box-shadow: 0px px-to-vw(4px) px-to-vw(12px) rgba(0, 0, 0, 0.25);
    border-radius: px-to-vw(12px);
    &-body {
      padding: px-to-vw(24px) px-to-vw(12px);
    }
  }
  &-product-image {
    width: 100%;
    height: auto;
    margin-bottom: px-to-vw(12px);
  }
  h4 {
    margin-bottom: px-to-vw(12px);
  }
  h5 {
    margin-bottom: px-to-vw(8px);
    min-height: px-to-vw(54px);
  }
  &-button {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    margin-top: px-to-vw(25px);
    img {
      width: px-to-vw(17px);
      margin-right: px-to-vw(12px);
    }
  }
}

.card {
  border: px-to-vw(3px) solid $g2;
  box-sizing: border-box;
  border-radius: px-to-vw(12px);
  &-body {
    padding: px-to-vw(62px) px-to-vw(36px);
  }
}

.alert {
  &-error {
    .modal-content {
      border: 3px solid #e53939;
    }
  }
  &-success {
    .modal-content {
      border: 3px solid #7dc832;
    }
  }
}

.buscador {
  .keyboardContainer {
    transform: translate(-40%);
    left: 50%;
  }
}
