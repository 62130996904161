@import "../config/typography";
@import "../config/variables";
@import "../config/mixins";

.login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
      0deg,
      rgba(255, 125, 0, 0.2),
      rgba(255, 125, 0, 0.2)
    ),
    #ffffff;

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 546px;
    height: 632px;
    background: #ffffff;
    box-shadow: 5px 5px 40px rgba(255, 153, 0, 0.15);
    border-radius: 12px;
    padding: 72px 60px;
    gap: 36px;

    .inputs {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      gap: 12px;
      input {
        width: 100%;
        @include input;
      }
    }
    button {
      @include button;
      width: 100%;
    }
  }
}

.keyboardContainer {
  left: 50%;
  transform: translate(-20%, 10px);
}
