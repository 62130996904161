@import '../config/functions';

* {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

body {
    font-family: 'Roboto', sans-serif;
    min-height: 100vh;
    display: flex;
    width: 100%;
}
  
#root,
#root > div:not(.header):not(.footer) {
    width: 100%;
    height: 100vh;
    background: #fff;
}

#root > div:not(.header):not(.footer) {
    width: 100%;
    height: calc(100vh - 5.27086vw - 3.51391vw);
    min-height: calc(100vh - 5.27086vw - 3.51391vw);
    overflow: hidden;
    overflow-y: auto;
}

.footer {
    height: 48px;
}

.transition {
    transition: all ease 0.5s;
}

.text-success {
    color: $secundario;
}

div:empty {
    display: none !important;
}