@import '../../styles/config/variables';
@import '../../styles/config/functions';

.footer {
    height: px-to-vw(48px);
    position: sticky;
    bottom: 0;
    left: 0;
    background: $blanco;
    border-top: 1px solid $g3;
    &-hold {
        width: px-to-vw(16px);
    }
    &-logo {
        &-cruce {height: px-to-vw(29px);}
        &-vtex {height: px-to-vw(20px);}
        &-fiscal {height: px-to-vw(30px);}
    }
}